import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ticket',
    name: 'Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Ticket.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router




import Zendesk from '@dansmaculotte/vue-zendesk'

Vue.use(Zendesk, {
  key: 'af9dbe14-0d1a-4bbf-9430-3c36359699ad',
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#20519e'
      }
    }
  }
});

