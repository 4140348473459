<template>
  <v-app>
    <TopNav />
    <v-main>
      <router-view></router-view>
      <v-footer app color="black" dark padless height="150">
        <v-card class="flex" flat tile>
          <v-card-title class="blue">
            <strong class="subheading">Media Sosial Resmi </strong>
            <router-link to="/">
              <v-img
                alt="Halo SNPMB"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="100"
                width="120"
                :src="require('./assets/logo.png')"
              />
              <div class="white--text text-darken-1 ml-2 hidden-sm-and-up">
                SNPMB
              </div>
            </router-link>
            <v-spacer></v-spacer>
            <v-layout class="flex justify-center grow">
              <v-img
                alt="Halo SNPMB"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="220"
                width="460"
                :src="require('./assets/sponsors.png')"
              />
            </v-layout>
            <v-spacer></v-spacer>

            <v-btn
              v-for="(link, icon) in sosmeds"
              v-bind:key="link"
              :href="link"
              target="_blank"
              class="mx-2"
              dark
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-2 white--text text-center">
            {{ new Date().getFullYear() }} —
            <strong>Halo <a href="https://www.bppp.kemdikbud.go.id">SNPMB</a></strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";

export default {
  name: "App",
  components: {
    TopNav,
  },
  data: () => ({
    sosmeds: {
      "mdi-facebook": "https://www.facebook.com/snpmb.bppp",
      "mdi-twitter": "https://twitter.com/snpmb_bppp",
      "mdi-instagram": "https://www.instagram.com/_snpmbbppp/",
      "mdi-youtube": "https://www.youtube.com/@snpmbbppp",
      "mdi-tiktok": "https://www.tiktok.com/@snpmb_bppp",
    },
  }),
  computed: {
    bgHeight() {
      return this.$vuetify.breakpoint.height;
    },
  },
};
</script>
