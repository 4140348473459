<template>
  <div class="home">
    <HelpdeskInfo/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelpdeskInfo from '@/components/HelpdeskInfo.vue'


export default {
  name: 'Home',
  components: {
    HelpdeskInfo
  }
}
</script>
