<template>
  <div>
    <v-container fluid fill-height class="pa-0">
      <v-img
        :src="require('@/assets/bg_image.jpg')"
        :lazy-src="require('@/assets/bg_image_blur.jpg')"
        :max-height="bgHeight"
        cover
        class="overflow-y-auto"
      >
            <v-row align="center" justify="center" 
                style="height:90vh" dense>
                <v-col cols="12" lg="12" md="12" class="d-flex flex-column justify-center align-right">

        <v-card
          color="transparent"
          class="overflow-y-auto"
          :max-height="vcardHeight"
          flat
          solo
        >
          <v-card-text>
            <h1 class="display-2 text--lighten-10 mb-10 text-right">
              <span class="blue--text">Helpdesk &amp; Call Center</span>
            </h1>
            <h2 class="display-1 mb-3 text-right">
              <span class="blue--text"
                >Layanan Call Center SNPMB Di Nomor</span
              >
            </h2>
            <h3 class="display-1 text--secondary mb-3 text-right">
              <span class="blue--text">0804-1-450-450</span>
            </h3>
            <h1 class="display-lg bold mb-3 text-right">
              <span class="blue--text">Jam layanan: 08.00 - 18.00 WIB</span>
            </h1>
            <div class="text-right">
              <v-btn 
              rounded 
              color="green" 
              dark
              x-large
              v-on:click="goToFaq" 
              >
                <v-icon class="black--text" small left>mdi-help-circle</v-icon> FAQ </v-btn>            
              <v-btn 
              rounded 
              color="primary" 
              dark
              x-large
              v-on:click="showWebWidget" 
              >
                <v-icon class="black--text" small left>mdi-message-text</v-icon> Buat Tiket </v-btn>
            </div>
          </v-card-text>
        </v-card>
         </v-col>
          </v-row>
      </v-img>
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {
    bgHeight() {
      return this.$vuetify.breakpoint.height-120;
    },
    vcardHeight() {
      return this.$vuetify.breakpoint.height+100;
    },    
  },
  mounted() {
    this.$zendesk.load("key");
  },
  methods: {
    showWebWidget() {
       window.location.assign('https://halo-snpmb.bppp.kemdikbud.go.id/helpdesk/id/penanya/create-ticket/');
    },
    goToFaq() {
       window.location.assign('https://halo-snpmb.bppp.kemdikbud.go.id/helpdesk/id/folder/4');    
    }
  },  
};
</script>



