<template>
  <div>
    <v-app-bar
      app
      :src="require('@/assets/bg_header_image.png')"
    >
      <template v-slot:img="{ props }">
        <v-img 
          v-bind="props"
        ></v-img>
      </template>

      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

      <div class="d-flex align-center">
        <v-toolbar-title class="mr-1 display-1 black--text">HALO SNPMB</v-toolbar-title
        >
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
module.exports = {
  data() {
    return {
      drawer: false,
      items: [
        { title: "Home", link: "/", icon: "home" },
      ],
    };
  },
};
</script>
