import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueGtag from "vue-gtag";

Vue.use(Vuetify);
Vue.use(VueGtag, {
    config: { id: "G-V9EE8X0GVK" }
});

export default new Vuetify({
});
